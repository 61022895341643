import MomsiteApiHandler from '../../momsite/src/services/api-handler.service'
import SuperadminApiHandler from '../../Momscradle-Superadmin/src/services/api-handler.service'

function getAPIHandler() {
  if( process.env.VUE_APP_SITE === 'Momscradle')
    return MomsiteApiHandler
  else
    return SuperadminApiHandler
}

class Config {
  api = getAPIHandler();
}
export default new Config()
