import axios from 'axios'

class ApiHandler {
  BASE_URL = process.env.VUE_APP_API_BASE_URL;

  DoNothing = () => {};
  ShowAlert = null;
  NoPaylaod = null;
  NoAuthRequires = true;
  AuthRequires = false;

  ///On promise rejection event converted to error from catch exception
  rejectPromise(thrown){
    if (axios.isCancel(thrown)) {
      return Promise.reject(thrown.message)
    } else {
      return Promise.reject(thrown)
    }
  }

  setConfig(noAuthRequires) {
    if(noAuthRequires) {
      return { headers: { 'Authorization': 'Bearer unset' } }
    } else {
      return { headers: { 'Authorization': '' } }
    }
  }

  //Sending null for onSuccess and onFail will trigger default call to display alert, Sending callback function will override the default
  getCall(APIURL, onSuccess, onFail, noAuthRequires){
    let config = this.setConfig(noAuthRequires)
    return axios.get(APIURL, config).then(
      response => {
        return this.eventSuccess(onSuccess, response)
      }, error => {
        return this.eventError(onFail, error)
      }).catch(function (thrown) { this.rejectPromise(thrown) })
  }

  //Sending null for onSuccess and onFail will trigger default call to display alert, Sending callback function will override the default
  deleteCall(APIURL, onSuccess, onFail, noAuthRequires){
    let config = this.setConfig(noAuthRequires)
    return axios.delete(APIURL, config).then(
      response => {
        return this.eventSuccess(onSuccess, response)
      }, error => {
        return this.eventError(onFail, error)
      }).catch(function (thrown) { this.rejectPromise(thrown) })
  }

  //Sending null for onSuccess and onFail will trigger default call to display alert, Sending callback function will override the default
  postCall(APIURL, payload, onSuccess, onFail, noAuthRequires){
    let config = this.setConfig(noAuthRequires)
    return axios.post(APIURL, payload, config).then(
      response => {
        return this.eventSuccess(onSuccess, response)
      }, error => {
        return this.eventError(onFail, error)
      }).catch(function (thrown) { this.rejectPromise(thrown) })
  }

  //Sending null for onSuccess and onFail will trigger default call to display alert, Sending callback function will override the default
  uploadCall(APIURL, payload, onSuccess, onFail, onProgress, noAuthRequires){
    let config = this.setConfig(noAuthRequires)
    config.onUploadProgress = onProgress
    return axios.post(APIURL, payload, config).then(
      response => {
        return this.eventSuccess(onSuccess, response)
      }, error => {
        return this.eventError(onFail, error)
      }).catch(function (thrown) { this.rejectPromise(thrown) })
  }

  //On success callback of an api call promise returned with alert displayed if no callback is sent
  eventSuccess(onSuccessCallback, response){
    if(onSuccessCallback)
      onSuccessCallback(response)
    else
      this.alertSuccess(response)
    return Promise.resolve(response)
  }

  //On error callback of an api call promise returned with alert displayed if no callback is sent
  eventError(onFailCallback, error){
    if(onFailCallback)
      onFailCallback(error)
    else
      this.alertError(error)
    return Promise.reject(error)
  }

  alertSuccess(response){
    if(response.data.Status)
      alert(response.data.Message, 'success')
    else
      this.alertError(response.data.Message)
  }
  alertError(error){
    alert(error.Message || error.toString(), 'danger')
  }
}
export default new ApiHandler()
