import { reactive, createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { configure } from 'vee-validate'
import VueScrollto from 'vue-scrollto'
import Particles from 'particles.vue3'
import ModalModel from '../../daoSource/models/ModalModel'
import Vue3Geolocation from 'vue3-geolocation'


configureVeeValidate()
interceptAxios()
manageRouteAccess()

const app = createApp(App)
app.config.globalProperties.$modal = reactive(new ModalModel())
app.use(store).use(router).use(VueScrollto)
app.mount('#app')
app.use(Particles)
app.use(Vue3Geolocation)


function interceptAxios (){
  // intercepting request
  axios.interceptors.request.use(function (config) {
    if (store.state.loggedInState && store.state.accessToken) {
      if(config.headers.Authorization === 'Bearer unset') {
        config.headers = {}
      } else {
        config.headers = {
          'Authorization' : 'Bearer ' + store.state.accessToken
        }
      }
    }
    return config
  }, function (error) {
    // Do something with request error
    return Promise.reject(error)
  })
  // intercepting responses
  axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response
  }, function (error) {
    // Do something with response error
    if (error && error.response && error.response.status === 401) {
      store.dispatch('unauthorizedAction')
    }
    return Promise.reject(error)
  })
}
function manageRouteAccess(){
  router.beforeEach((to, from, next) => {
    if(store.state.loggedInState) { //If logged in then login, signup is not accessible, so stays where route were
      if(['login','signup'].includes(to.name))
        return next({ name: from.name })
    }
    if (to.meta.isPublic)
      return next()
    else if (store.state.loggedInState)
      return next()
    else
      return next({ name:'login' })
  })
}
function configureVeeValidate(){
  // Default values
  configure({
    validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
    validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
    validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
    validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
  })
}
