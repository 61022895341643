<template>
  <Header></Header>
  <div class="body-contents">
    <router-view/>
  </div>
  <Footer></Footer>
</template>
<script>
// @ is an alias to /src
import Header from './Header.vue'
import Footer from './Footer'

export default {
  name: 'AppLayout',
  components: {
    Header,
    Footer
  }
}
</script>

