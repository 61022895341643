import UtilityService from '../services/utility.service'
export default class UserProfile {
  constructor () {
    this.id = 0
    this.IsEmailVerified = false
    this.FName = ''
    this.FullName = ''
    this.LName = ''
    this.RegDate = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
    this.UsrType = 2
    this.VerifiedAt = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
    this.companyName = ''
    this.email = ''
    this.phone = ''
    this.CreatedAt = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
    this.LoggedInAt = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
    this.UpdatedAt = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
  }

  load(data) {
    this.id = data.id
    this.IsEmailVerified = data.IsEmailVerified
    this.FName = data.FName
    this.FullName = data.FullName
    this.LName = data.LName
    this.RegDate = UtilityService.formatDateTime(data.RegDate, 'MM/DD/YY hh:mm A')
    this.UsrType = data.UsrType
    this.VerifiedAt = UtilityService.formatDateTime(data.VerifiedAt, 'MM/DD/YY hh:mm A')
    this.companyName = data.companyName
    this.email = data.email
    this.phone = data.phone
    this.CreatedAt = UtilityService.formatDateTime(new Date(), 'MM/DD/YY hh:mm A')
    this.LoggedInAt = UtilityService.formatDateTime(data.LoggedInAt, 'MM/DD/YY hh:mm A')
    this.UpdatedAt = UtilityService.formatDateTime(data.UpdatedAt, 'MM/DD/YY hh:mm A')
    return this
  }
}
