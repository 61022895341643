<template>
<Modal @close="close" :modalActive="modalModel ? modalModel.modalActive : modalActive ">
      <div class="">
        <div class="mb-2">
          <h5 v-html="modalModel ? modalModel.title : title"></h5>
        </div>
        <p class="mb-4" v-html="modalModel ? modalModel.subTitle : subTitle"></p>
       </div>
      <div class="float-right">
         <button v-show="modalModel ? modalModel.nBtn : nBtn" @click="close" class="btn bg-secondary  mr-2">{{ modalModel ? modalModel.nBtn : nBtn }}</button>
        <button v-show="modalModel ? modalModel.yBtn : yBtn" @click="onApproveAction" class="btn">{{ modalModel ? modalModel.yBtn : yBtn }}</button>
      </div>
    </Modal>
</template>

<script>

import Modal from './Modal-Base'

export default {
  name: 'ModalDialog',
  data(){
    return {
    }
  },
  props: [
    'modalActive',
    'title',
    'subTitle',
    'yBtn',
    'nBtn',
    'modalData',
    'modalModel'
  ],
  components:{
    Modal
  },
  methods:{
    onApproveAction(){
      if( (this.modalModel && this.modalModel.yBtn ) || this.yBtn != null)
        this.approve()
    },
    close () {
      this.$emit('close', this.modalModel ? this.modalModel.modalData : this.modalData)
    },
    approve () {
      this.$emit('approve', this.modalModel ? this.modalModel.modalData : this.modalData)
      this.close()
    }
  }
}
</script>

<style >
.modal{
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.modal .modal-inner{
  width:auto ;
  padding: 50px 16px 16px 16px ;
}

.modal .modal-inner i {
  display: inline-block;
    color: lightgrey;
    margin-bottom: 20px;
}
.modal .modal-inner i:hover {
    color: grey;
}
</style>
