export default class ModalModel {
  constructor () {
    this.modalActive = false
    this.modalData = {}
    this.title = ''
    this.subTitle = ''
    this.yBtn = 'Yes'
    this.nBtn = 'No'
    this.approveCallback = this.close
    return this
  }
  set (modalActive, modalData, title, subTitle, yBtn, nBtn, approveCallback) {
    this.modalActive = modalActive
    this.modalData = modalData
    this.title = title
    this.subTitle = subTitle
    this.yBtn = yBtn
    this.nBtn = nBtn
    this.approveCallback = approveCallback ? approveCallback : this.approveCallback
    return this
  }
  load (data) {
    this.set(
      data.modalActive,
      data.modalData,
      data.title,
      data.subTitle,
      data.yBtn,
      data.nBtn,
      data.approveCallback
    )
    return this
  }
  close () {
    this.modalActive = false
  }
  open () {
    this.modalActive = true
  }
}
