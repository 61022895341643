export default class GeneralAPIResponseModel {
  constructor (Status, Message, HttpStatusCode, obj) {
    this.Status = Status
    this.Message = Message
    this.HttpStatusCode = HttpStatusCode
    this.Object = obj
  }
  load(data) {
    this.Status = data.Status
    this.Message = data.Message
    this.HttpStatusCode = data.HttpStatusCode
    this.Object = data.Object
    return this
  }

}
