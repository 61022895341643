<template>
  <Modal  :modalModel="this.$modal" @approve="this.$modal.approveCallback" @close="this.$modal.close()" />
  <router-view />
</template>

<script>
import store from './store'
import UserService from '../../daoSource/services/user.service'
import Modal from '../../daoSource/components/ui/ModalDialog'

export default {
  name: 'App',
  data() {
    return {
      RefreshTokenInterval: process.env.VUE_APP_RefreshTokenIntervalInMinutes * 60 * 1000,
    }
  },
  components: {
    Modal
  },
  mounted() {
    if(this.$store.state.loggedInState){
      this.$store.dispatch('loadSessionUser')
    }
    this.initiateRefreshToken()
  },
  watch: {
    $route (to) {
      this.onRouteChange(to)
    }
  },
  methods: {
    onRouteChange (route) {
      document.title = route.meta.title || 'Momscradle | Superadmin'
    },
    initiateRefreshToken: function (){
      let vm = this
      this.refreshToken()
      setInterval(() => {
        vm.refreshToken()
      }, this.RefreshTokenInterval)
    },
    refreshToken: function () {
      if (store.state.loggedInState) {
        UserService.refreshMomsiteUserAccessToken().then((response) => {
          if (response && response.data.Status) {
            this.$store.dispatch(
              'refreshAccessToken',
              response.data.Object.AccessToken
            )
          } else {
            this.$store.dispatch('unauthorizedAction')
          }
        })
      }
    },
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
