<template>
  <header class="d-print-none">
    <!-- ==============================================
    **Header**
    =================================================== -->

    <div class="header-top navbar-expand-md" :class="{'fixed-top' : $route.name == 'home' }">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault"
        aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon fa fa-bars text-light"></span>
      </button>
      <!-- for smaller displays -->
      <router-link class="navbar-brand d-md-none" :to="{ name: 'home' }">
        <img :src="`${baseURL}assets/images/CM-Logo.png`" class="img-fluid" alt="" style="max-width: 75%;"/>
      </router-link>
      <div class="collapse navbar-collapse" id="navbarsExampleDefault">

        <div class="col-12 d-md-none"
          v-show="!$store.state.loggedInState || ($store.state.loggedInState && user.IsEmailVerified)">
        </div>
        <div class="container-fluid  header-container d-flex align-items-center justify-content-between">
          <div class="clearfix logo-lg">
            <router-link class="navbar-brand" :to="{ name: 'home' }">
              <img  :src="`${baseURL}/assets/images/CM-Logo.png`" class="logo-lg img-fluid mt-2 mb-2" alt="" style="height: 50px;" />
            </router-link>
          </div>
          <div class="clearfix d-flex  justify-content-sm-start justify-content-md-end">
            <ul class="top-nav">
              <li class="nav-item" :class="{ active: $route.name == 'dashboard' }" v-if="$store.state.loggedInState">
                <router-link class="nav-link" :to="{ name: 'dashboard' }"
                  :class="{ active: $route.name == 'dashboard' }">Dashboard</router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link pointer-cursor" target="_blank" :href="supportURL+'/blog'">Blog</a>
              </li>
              <li class="nav-item">
                <a class="nav-link pointer-cursor" :href="`${baseURL}#case-study`" @click="scrollToElement($event, 'case-study')">Case Study</a>
              </li>
              <li class="nav-item">
                <router-link :to="{ name: 'pricing' }" class="nav-link">Pricing</router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link pointer-cursor" target="_blank" :href="supportURL+'/home'">Support</a>
              </li>
              <li class="nav-item dropdown" v-if="$store.state.loggedInState">
                <a class="nav-link dropdown-toggle pointer-cursor" data-toggle="dropdown" :class="{
                  active:
                    $route.name == 'profile' ||
                    $route.name == 'payment-setup',
                }" aria-haspopup="true" aria-expanded="false">{{ this.user.FullName != " " ? this.user.FullName : "User" }}
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdown1">
                  <div class="inner">

                    <router-link :to="{ name: 'profile', query: { redirect: this.$route.fullPath } }"
                      class="dropdown-item" :class="{ active: $route.name == 'profile' }">Update Profile</router-link>
                    <router-link v-show="$store.state.loggedInState && user.IsEmailVerified
                      " class="dropdown-item" :class="{ active: $route.name == 'billing-and-payout-setup' }"
                      :to="{ name: 'billing-and-payout-setup' }">Billing and Payout Setup</router-link>

                    <a class="dropdown-item pointer-cursor" @click="handleSession">Logout</a>
                  </div>
                </div>
              </li>
              <li class="nav-item" v-if="!$store.state.loggedInState && $route.name != 'signup'">
                <router-link :to="{ name: 'signup' }" class="nav-link">Signup</router-link>
              </li>
              <li class="nav-item" v-if="!$store.state.loggedInState && $route.name != 'login'">
                <a class="nav-link pointer-cursor" @click="handleSession">Login</a>
              </li>
            </ul>
          </div>
        </div>


      </div>
    </div>
    <!-- Start Navigation -->
    <!-- End Navigation -->
  </header>
</template>

<script>
import UserModel from '../../../../daoSource/models/UserModel'
export default {
  name: 'Header',
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      user: new UserModel(),
      fullSearchText: '',
      supportURL: process.env.VUE_APP_SUPPORT_BASE_URL
    }
  },
  mounted() {
    this.getUserInfo()
    const element = document.getElementById('case-study')
    if (element) {
      const offsetPosition =
        element.getBoundingClientRect().top + window.pageYOffset - 50
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  },
  watch: {
    '$route.params.searchKey': function () {
      if (this.$route.params.searchKey) {
        this.fullSearchText = this.$route.params.searchKey
      }
    },
  },
  methods: {
    getUserInfo() {
      if (this.$store.state.loggedInState) {
        this.$store.dispatch('loadSessionUser').then((response) => {
          if (response.data.Status) this.user.load(this.$store.state.userData)
        })
      }
    },
    handleSession: function () {
      if (this.$store.state.loggedInState) {
        this.$store.dispatch('logout')
      }
      this.$router.push({ name: 'login' })
    },
    searchForAllResult: function () {
      console.log('Search clicked ' + this.fullSearchText)
      if (this.fullSearchText) {
        this.$router.push('/birthing-centers/' + this.fullSearchText)
      }
    },
    clearSearchBox: function () {
      this.fullSearchText = ''
    },
    onRequestYourStoreClick() {
      if (this.$store.state.loggedInState) {
        if (this.user.IsEmailVerified) {
          this.$router.push({ name: 'request-for-shop' })
        } else {
          alert('Please verify your account first!', 'warning')
        }
      } else {
        this.pushToLogin()
      }
    },
    onAddYourBirthCenterClick() {
      if (this.$store.state.loggedInState) {
        if (this.user.IsEmailVerified) {
          this.$router.push({ name: 'add-birthing-center' })
        } else {
          alert('Please verify your account first!', 'warning')
        }
      } else {
        this.pushToLogin()
      }
    },
    pushToLogin() {
      if (this.$route.name !== 'login') {
        this.$router.push({ name: 'login' })
      } else {
        alert('Please login first!', 'warning')
      }
    },
    scrollToElement(event, id) {
      if(this.$route.name === 'home'){
        event.preventDefault()
      }
      const element = document.getElementById(id)
      if (element) {
        const offsetPosition =
          element.getBoundingClientRect().top + window.pageYOffset - 50
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        })
      }
    }
  }
}
</script>
