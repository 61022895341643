import AuthService from '../services/auth/auth.service'
import GeneralAPIResponseModel from '../../../daoSource/models/GeneralAPIResponseModel'
import UserModel from '../../../daoSource/models/UserModel'
import UserService from '../../../daoSource/services/user.service'
import router from '../router'
import { createStore } from 'vuex'
import { reactive } from 'vue'

const keyMomsiteStorage = 'MomsiteAccessToken'
let AccessToken = localStorage.getItem(keyMomsiteStorage)

export default createStore({
  state: reactive({
    loggedInState: !!AccessToken,
    accessToken: AccessToken,
    userData: null
  }),
  actions: {
    loadSessionUser ({ commit }) {
      return new Promise((resolve, reject) => {
        if(!this.state.loggedInState){
          alert('Please log in to access user information.','danger')
          return reject('Please log in to view your profile.')
        }
        if(this.state.userData === null){
          UserService.getCurrentUser().then(response => {
            if(response.data.Status)
              commit('setUserData', new UserModel().load(response.data.Object.user))
            return resolve(response)
          },
          error => { return reject(error) })
        } else {
          return resolve({ data: new GeneralAPIResponseModel(true, 'Data found', 200, { user : this.state.userData }) })
        }
      })
    },
    login ({ commit }, loginModel) {
      return AuthService.login(loginModel).then(
        response => {
          if(response && response.data){
            if(response.data.Status){
              commit('setLoggedInStateAndToken', response.data.Object.accessToken)
            } else {
              commit('logout')
            }
          }
          return Promise.resolve(response)
        },
        error => {
          commit('logout')
          return Promise.reject(error)
        }
      )
    },
    logout ({ commit }) {
      commit('logout')
    },
    refreshAccessToken ({ commit }, accessToken) {
      commit('setLoggedInStateAndToken', accessToken)
    },
    unauthorizedAction ({ commit }) {
      if (router.currentRoute.name !== 'login') {
        alert('Either session is expired or you are not authorized for the action. Please try login again.', 'warning')
        commit('logout')
        router.push({ name:'login' })
      }
    },
  },
  mutations: {
    setUserData (state, userData) {
      state.userData = userData
    },
    setLoggedInStateAndToken (state, accessToken) {
      state.loggedInState = true
      state.accessToken = accessToken
      localStorage.setItem(keyMomsiteStorage, accessToken)
    },
    logout (state) {
      localStorage.removeItem(keyMomsiteStorage)
      state.loggedInState = false
      state.userData = null
      state.accessToken = null
    },
  },
  modules: {},
})
